import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { noop } from 'libs';

const isActive = (location, paths) =>
  paths.reduce((accum, path) => {
    if (location.pathname.includes(path)) {
      return true;
    }
    return accum;
  }, false);

const NavItem = ({ disabled = false, hidden = false, onClick = noop, paths, title = '' }) => {
  const location = useLocation();
  const classes = ['nav-item', 'nav-link'];

  if (hidden) {
    return null;
  }

  if (isActive(location, paths)) {
    classes.push('active');
  }

  return (
    <a key={title} className={classes.join(' ')} href={paths[0]} disabled={disabled} onClick={onClick}>
      {title}
    </a>
  );
};

/**
 * PropTypes Validation
 * @type {Object}
 */
NavItem.propTypes = {
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  onClick: PropTypes.func,
  paths: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string,
};

export default NavItem;
