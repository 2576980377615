import React from 'react';
import PropTypes from 'prop-types';

const SlideToggle = ({ active, handleToggle, target }) => (
  <div className="slide-item" data-testid={`slide-toggle-${target}`}>
    <input id={`toggle_reminders_${target}`} type="checkbox" onChange={() => handleToggle(target)} checked={active} />
    <div className="toggle">
      <label htmlFor={`toggle_reminders_${target}`}>
        <i />
      </label>
    </div>
  </div>
);

SlideToggle.propTypes = {
  active: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
  target: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default SlideToggle;
