import React from 'react';
import PropTypes from 'prop-types';

const Error = ({ children = '' }) => <span className="help-block invalid-feedback">{children}</span>;

Error.propTypes = {
  children: PropTypes.node,
};

export default Error;
