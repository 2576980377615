import capitalize from 'lodash/capitalize';
import moment from 'moment';
import numeral from 'numeral';

const exitSurveyOptions = [
  { commentRequired: false, key: 1, value: 'No longer brokering/changed role' },
  { commentRequired: false, key: 2, value: 'Using a different tool/application' },
  { commentRequired: false, key: 3, value: 'Business slow/new to brokering' },
  { commentRequired: false, key: 4, value: 'Cost too high' },
  { commentRequired: true, key: 7, value: 'Missing important features for my business' },
  { commentRequired: true, key: 8, value: 'Other' },
];

const platinumOnlyExitSurveyOptions = [
  { commentRequired: false, key: 5, value: 'Connected to Brokerage or Finmo' },
  { commentRequired: false, key: 6, value: 'Get enough value from Lender Spotlight Basic' },
];

export const addonExitSurveyOptions = exitSurveyOptions;

export const platinumExitSurveyOptions = exitSurveyOptions
  .concat(platinumOnlyExitSurveyOptions)
  .sort((a, b) => a.key - b.key);

export const formatCardBrand = (cardBrand) => {
  switch (cardBrand) {
    case 'amex':
      return 'American Express';
    case 'mastercard':
      return 'Master Card';
    default:
      return capitalize(cardBrand);
  }
};

export const formatPrice = (price) => `${numeral(price.price / 100).format('$0,0')} / ${capitalize(price.frequency)}`;

export const hasAddon = (subscriptions, addon) =>
  Boolean(
    subscriptions.filter(
      (subscription) => subscription.stripe_status !== 'canceled' && subscription.stripe_price === addon.stripe_price
    ).length
  );

export const isActivePlatinumSubscription = (subscription) =>
  subscription.name === 'platinum' && (!subscription.ends_at || moment.utc() < moment.utc(subscription.ends_at));

export const isAddonActive = (subscriptions, addon) =>
  Boolean(subscriptions.find((subscription) => subscription.stripe_price === addon.stripe_price).ends_at === null);
