import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button } from 'components/common';
import { Text } from 'components/forms';

const schema = yup.object({
  current_password: yup.string().required('Please enter your new password.'),
  current_password_confirmation: yup
    .string()
    .required('Please confirm your new password.')
    .oneOf([yup.ref('current_password')], 'Passwords must match.'),
  password_old: yup.string().required('Please enter your current password.'),
});

const PasswordChangeForm = ({ onSubmit }) => {
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
    reset,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmitForm = async (data) => {
    await onSubmit(data, reset, setError);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Text
        error={errors.password_old}
        label="Current Password"
        name="password_old"
        register={register('password_old')}
        type="password"
      />
      <Text
        error={errors.current_password}
        label="Password"
        name="current_password"
        register={register('current_password')}
        type="password"
      />
      <Text
        error={errors.current_password_confirmation}
        label="Confirm Password"
        name="current_password_confirmation"
        register={register('current_password_confirmation')}
        type="password"
      />
      <Button disabled={isSubmitting} submit text="bold" variant="primary-dark">
        Change Password
      </Button>
    </form>
  );
};

PasswordChangeForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default PasswordChangeForm;
