import { combineReducers } from 'redux';
import brokeragesReducer, * as brokerages from './brokerages';
import contactsReducer, * as contacts from './contacts';
import filtersReducer, * as filters from './filters';
import guidelinesReducer, * as guidelines from './guidelines';
import invitationsReducer, * as invitations from './invitations';
import lendersReducer, * as lenders from './lenders';
import lendersByProductsReducer, { actions as lendersByProducts } from './lendersByProducts';
import networksReducer from './networks';
import notificationReducer from './notifications';
import pricesReducer from './prices';
import policiesReducer from './policies';
import productsReducer, * as products from './products';
import programsReducer from './programs';
import regionsReducer from './regions';
import searchesReducer, * as searches from './searches';
import settingsReducer, * as settings from './settings';
import statusesReducer from './statuses';
import termsReducer from './terms';
import usersReducer, * as users from './users';
import * as auth from './auth';

export const rootReducer = combineReducers({
  brokerages: brokeragesReducer,
  contacts: contactsReducer,
  filters: filtersReducer,
  guidelines: guidelinesReducer,
  invitations: invitationsReducer,
  lenders: lendersReducer,
  lendersByProducts: lendersByProductsReducer,
  networks: networksReducer,
  notifications: notificationReducer,
  prices: pricesReducer,
  policies: policiesReducer,
  products: productsReducer,
  programs: programsReducer,
  regions: regionsReducer,
  searches: searchesReducer,
  settings: settingsReducer,
  statuses: statusesReducer,
  terms: termsReducer,
  users: usersReducer,
});

export default {
  auth,
  brokerages,
  contacts,
  filters,
  guidelines,
  invitations,
  lenders,
  lendersByProducts,
  products,
  searches,
  settings,
  users,
};
