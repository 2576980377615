import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Lenders, LenderDetail } from 'pages';

const LendersLayout = () => (
  <Switch>
    <Route exact path="/lenders">
      <Lenders />
    </Route>
    <Route path="/lenders/:id(\d+)">
      <LenderDetail />
    </Route>
  </Switch>
);

export default LendersLayout;
