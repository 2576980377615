import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useController } from 'react-hook-form';
import { Select as CommonSelect } from 'components/common';
import Error from './Error';
import Label from './Label';

const Select = ({
  control,
  disabled = false,
  error,
  isMulti = false,
  label,
  name,
  options,
  overflowContainer = false,
  placeholder = 'Select an option',
}) => {
  const { field } = useController({ control, name });

  const handleSelected = (opts, values) =>
    isMulti
      ? opts.filter((item) => values?.some((value) => value === item.value))
      : opts.find((item) => item.value === values);

  const handleChange = (selectedOption) =>
    isMulti
      ? field.onChange(selectedOption?.map((option) => option.value) ?? []) // When multiple selections are allowed, return an array of selected options
      : field.onChange(selectedOption ? selectedOption.value : null); // Single selection - only one value

  return (
    <div className="my-3">
      <Label name={field.name}>{label}</Label>
      <CommonSelect
        className={classnames('form-react-select', { 'is-invalid': error })}
        disabled={disabled}
        isMulti={isMulti}
        onChange={handleChange}
        options={options}
        overflowContainer={overflowContainer}
        placeholder={placeholder}
        selected={handleSelected(options, field.value)}
      />
      <Error>{error?.message}</Error>
    </div>
  );
};

Select.propTypes = {
  control: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.shape({
    message: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  }),
  isMulti: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ).isRequired,
  overflowContainer: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default Select;
