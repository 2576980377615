import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { RateCompare, RateDetail, Rates } from 'pages';
import { useUser } from 'libs';

const RatesLayout = () => {
  const location = useLocation();
  const user = useUser();
  return (
    <Switch>
      <Route path="/rates/:id(\d+)">
        <RateDetail />
      </Route>
      {user.features.show_compare && (
        <Route exact path="/rates/compare">
          <RateCompare />
        </Route>
      )}
      <Route exact path="/rates/lenders">
        <Rates />
      </Route>
      <Route exact path="/rates/lenders/:id(\d+)">
        <Rates />
      </Route>
      <Route exact path="/rates/products">
        <Rates />
      </Route>
      <Route path="/rates" render={() => <Redirect to={{ pathname: '/rates/lenders', search: location.search }} />} />
    </Switch>
  );
};

export default RatesLayout;
