import React from 'react';
import PropTypes from 'prop-types';
import { analytics } from 'libs';
import { Select } from 'components/common';
import Filter from './Filter';

const AmortizationFilter = ({ filters, onFilter }) => {
  const options = [
    { label: '25 Years', value: '25' },
    { label: '30 Years', value: '30' },
    { label: '35 Years', value: '35' },
  ];

  const onChange = (option, action) => {
    if (action === 'clear') analytics.productSearch.filter({ amortization: 'cleared' });
    if (action === 'select-option') analytics.productSearch.filter({ amortization: option?.label });
    onFilter({ amortization: option?.value });
  };

  return (
    <Filter name="Maximum Amortization">
      <Select
        className="filter__select"
        clearValue={null}
        onChange={onChange}
        options={options}
        placeholder="Select amortization"
        selected={options.find((opt) => opt.value === filters.amortization)}
        testId="filter-amortization"
      />
    </Filter>
  );
};

AmortizationFilter.propTypes = {
  filters: PropTypes.shape({
    amortization: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  onFilter: PropTypes.func.isRequired,
};

export default AmortizationFilter;
