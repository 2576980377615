import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Error from './Error';
import Label from './Label';

export const Text = forwardRef(
  ({ disabled = false, error, label, name, placeholder = null, register, testId = '', type = 'text' }, ref) => (
    <div className="form-group">
      <Label name={name}>{label}</Label>
      <input
        className={classnames('form-control', { 'is-invalid': error })}
        data-testid={testId}
        disabled={disabled}
        id={name}
        label={label}
        name={name}
        placeholder={placeholder}
        ref={ref}
        type={type}
        {...register}
      />
      <Error>{error?.message}</Error>
    </div>
  )
);

Text.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.shape({
    message: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  }),
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  register: PropTypes.object.isRequired,
  testId: PropTypes.string,
  type: PropTypes.string,
};

export default Text;
