import React from 'react';
import propTypes from 'prop-types';

const FloatingTextbox = ({ children, text = null }) => (
  <span className="ms-2 position-relative opacity-transition-anchor">
    {children}
    {text && (
      <span className="bg-text left-4 opacity-0 opacity-transition pointer-events-none position-absolute px-2 py-1 radius-0_125 text-white text-0_75 top-6 z-2">
        {text}
      </span>
    )}
  </span>
);

FloatingTextbox.propTypes = {
  children: propTypes.node.isRequired,
  text: propTypes.string,
};

export default FloatingTextbox;
