import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Error from './Error';
import Label from './Label';

const Textarea = ({ error = null, label, name, placeholder = '', register, testId = '' }) => (
  <>
    <Label name={name}>{label}</Label>
    <textarea
      className={classnames('form-control', { 'is-invalid': error })}
      data-test-id={testId}
      id={name}
      placeholder={placeholder}
      {...register}
    />
    <Error>{error?.message}</Error>
  </>
);

Textarea.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  }),
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  register: PropTypes.object.isRequired,
  testId: PropTypes.string,
};

export default Textarea;
