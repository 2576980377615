import React from 'react';
import PropTypes from 'prop-types';

const CheckBox = ({ id, label, register, testId = null }) => (
  <div className="align-items-center d-flex form-check mb-3">
    <input className="form-check-input" data-testid={testId} id={id} type="checkbox" {...register} />
    <label className="form-check-label ps-2 text-0_875" htmlFor={id}>
      {label}
    </label>
  </div>
);

CheckBox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  label: PropTypes.string.isRequired,
  register: PropTypes.object.isRequired,
  testId: PropTypes.string,
};

export default CheckBox;
